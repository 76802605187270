<template>
  <div class="create-content-main list-user">
    <div
      class="list-user-table"
      style="
        box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
        padding-left: 0;
        padding-right: 0;
        border-radius: revert;
      "
    >
      <div class="d-flex align-items-center justify-content-between px-4">
        <span class="font-title" v-if="isEdit">ユーザー編集</span>
        <span class="font-title" v-else>ユーザー新規登録</span>
        <div v-if="isEdit">
          <CButton
            class="btn mr-2"
            @click="goToListNote()"
            style="background-color: #00b797; height: 42px; color: #fff"
            >ノート一覧</CButton
          >
          <CButton
            @click="goToListPurchase()"
            class="btn"
            style="background-color: #00b797; height: 42px; color: #fff"
            >購入履歴
          </CButton>
        </div>
      </div>
      <div class="mt-4">
        <div class="create-user">
          <CRow>
            <CCol sm="12">
              <label class="cp-label"
                >姓<span class="required" v-if="!isEdit">(必須)</span></label
              >
              <CInput
                class="cp-4 m-0"
                v-model="infoUser.lastName"
                :isValid="errorField.lastNameRequired"
                :invalidFeedback="messageError.lastNameRequired"
              />
            </CCol>
            <CCol sm="12">
              <label class="cp-label"
                >名<span class="required" v-if="!isEdit">(必須)</span></label
              >
              <CInput
                class="cp-4 m-0"
                v-model="infoUser.firstName"
                :isValid="errorField.firstNameRequired"
                :invalidFeedback="messageError.firstNameRequired"
              />
            </CCol>
            <CCol sm="12">
              <label class="cp-label"
                >メールアドレス<span class="required">(必須)</span></label
              >
              <CInput
                class="cp-4 m-0"
                v-model="infoUser.email"
                :isValid="
                  errorField.emailRequired === false
                    ? errorField.emailRequired
                    : errorField.emailInvalid === false
                    ? errorField.emailInvalid
                    : errorField.emailMaxlength === false
                    ? errorField.emailMaxlength
                    : null
                "
                :invalidFeedback="
                  messageError.emailRequired !== ''
                    ? messageError.emailRequired
                    : messageError.emailInvalid !== ''
                    ? messageError.emailInvalid
                    : messageError.emailMaxlength !== ''
                    ? messageError.emailMaxlength
                    : ''
                "
                :disabled="isEdit"
              />
            </CCol>
            <CCol sm="12">
              <label class="cp-label">生年月日</label>
              <div class="cp-4" style="display: flex">
                <CInput
                  class="mr-3 mb-0"
                  placeholder="YYYY"
                  v-model="infoUser.yearBirthDay"
                  onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                  maxLength="4"
                  :isValid="errorField.yearInvalid"
                  :invalidFeedback="messageError.yearInvalid"
                />
                <CInput
                  class="mr-3 mb-0"
                  placeholder="MM"
                  v-model="infoUser.monthBirthDay"
                  onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                  maxLength="2"
                  :isValid="errorField.monthInvalid"
                  :invalidFeedback="messageError.monthInvalid"
                  @blur="formatMonthAndDate"
                />
                <CInput
                  placeholder="DD"
                  v-model="infoUser.dateBirthDay"
                  onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                  maxLength="2"
                  :isValid="errorField.dateInvalid"
                  :invalidFeedback="messageError.dateInvalid"
                  @blur="formatMonthAndDate"
                  class="mb-0"
                />
              </div>
            </CCol>
            <CCol sm="12">
              <label class="cp-label">郵便番号</label>
              <div class="cp-4 m-0" style="display: flex">
                <CInput
                  class="mr-3 mb-0"
                  placeholder="100"
                  v-model="infoUser.zipCodeOne"
                  v-on:blur="
                    getAddress(`${infoUser.zipCodeOne}${infoUser.zipCodeTwo}`)
                  "
                  onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                  maxLength="3"
                  :isValid="errorField.zipCodeInvalid"
                  :invalidFeedback="messageError.zipCodeInvalid"
                />
                <CInput
                  placeholder="1000"
                  v-model="infoUser.zipCodeTwo"
                  v-on:blur="
                    getAddress(`${infoUser.zipCodeOne}${infoUser.zipCodeTwo}`)
                  "
                  onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                  maxLength="4"
                  :isValid="errorField.zipCodeInvalid"
                  :invalidFeedback="messageError.zipCodeInvalid"
                  class="mb-0"
                />
              </div>
            </CCol>
            <CCol sm="12">
              <label class="cp-label">都道府県</label>
              <CInput class="cp-4 m-0" v-model="infoUser.prefecture" disabled />
            </CCol>
            <CCol sm="12">
              <label class="cp-label">市区町村</label>
              <CInput class="cp-4 m-0" v-model="infoUser.city" disabled />
            </CCol>
            <CCol sm="12">
              <label class="cp-label">町名</label>
              <CInput class="cp-4 m-0" v-model="infoUser.area" disabled />
            </CCol>
            <CCol sm="12">
              <label class="cp-label">住所</label>
              <CInput class="cp-4 m-0" v-model="infoUser.address" />
            </CCol>
            <CCol sm="12">
              <label class="cp-label">建物名・部屋番号</label>
              <CInput
                class="cp-4 m-0"
                placeholder=""
                v-model="infoUser.building"
              />
            </CCol>
            <CCol sm="12">
              <label class="cp-label">電話番号</label>
              <div class="cp-4 m-0" style="display: flex">
                <CInput
                  class="mr-3 input-profile"
                  v-model="infoUser.phone1"
                  onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                  maxLength="6"
                  :isValid="infoUser.phoneInvalid1"
                  :invalidFeedback="infoUser.messPhoneInvalid1"
                />
                <CInput
                  class="mr-3 input-profile"
                  v-model="infoUser.phone2"
                  onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                  maxLength="4"
                  :isValid="infoUser.phoneInvalid2"
                  :invalidFeedback="infoUser.messPhoneInvalid2"
                />
                <CInput
                  v-model="infoUser.phone3"
                  onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                  maxLength="4"
                  :isValid="infoUser.phoneInvalid3"
                  :invalidFeedback="infoUser.messPhoneInvalid3"
                  class="input-profile"
                />
              </div>
            </CCol>
            <CCol sm="12">
              <label class="cp-label">ステータス</label>
              <CSelect
                class="cp-4 m-0"
                v-model="infoUser.status"
                :options="optionsStatus"
                :disabled="infoUser.isUserDeleted"
              />
            </CCol>
            <CCol sm="12" v-if="isEdit">
              <label class="cp-label">会員登録日時</label>
              <div class="cp-4 m-0">
                <date-picker
                  name="date"
                  v-model="infoUser.timeCreate"
                  :config="optionsDate"
                  autocomplete="off"
                  style="margin-bottom: 1rem"
                  disabled
                  class="mb-0"
                ></date-picker>
              </div>
            </CCol>
            <CCol sm="12" v-if="isEdit">
              <label class="cp-label">最終ログイン日時</label>
              <div class="cp-4 m-0">
                <date-picker
                  name="date"
                  v-model="infoUser.timeLogin"
                  :config="optionsDate"
                  autocomplete="off"
                  style="margin-bottom: 1rem"
                  class="mb-0"
                  disabled
                ></date-picker>
              </div>
            </CCol>
            <CCol sm="12" v-if="isEdit">
              <label class="cp-label">新しいパスワード</label>
              <CInput
                class="cp-4 m-0"
                v-model="infoUser.password"
                type="password"
                autocomplete="new-password"
                v-bind:class="{
                  'error-input':
                    infoUser.passwordInvalid || infoUser.passwordMaxlength,
                }"
              />
              <div class="message-error" v-if="infoUser.passwordMaxlength">
                {{ feedback.PASSWORD_8_CHARACTER }}
              </div>
              <div class="message-error" v-if="infoUser.passwordInvalid">
                {{ feedback.ALPHANUMERIC_PASSWORD }}
              </div>
            </CCol>
            <CCol sm="12">
              <label class="cp-label">スラグ設定</label>
              <div class="add-slag">
                <div class="custom-select-2 w-100 custome-multi-slag cp-4 m-0">
                  <multiselect
                    v-model="infoUser.slags"
                    tag-placeholder="スラグ追加"
                    placeholder="スラグ追加"
                    label="slag_name"
                    track-by="slag_name"
                    :options="listSlagOptions"
                    :multiple="true"
                    :taggable="false"
                    :showNoResults="false"
                    :select-label="'入力して選択する'"
                    :deselect-label="'入力して削除する'"
                    :selected-label="'選択された'"
                  ></multiselect>
                </div>
              </div>
            </CCol>
            <CCol sm="12">
              <label class="cp-label">コンテンツ設定</label>
              <div class="add-slag">
                <div class="custom-select-2 w-100 custome-multi-slag cp-4 m-0">
                  <multiselect
                    v-model="infoUser.contents"
                    tag-placeholder="コンテンツ追加"
                    placeholder="コンテンツ追加"
                    label="content_title"
                    track-by="content_title"
                    :options="listContentOptions"
                    :multiple="true"
                    :taggable="false"
                    :showNoResults="false"
                    :select-label="'入力して選択する'"
                    :deselect-label="'入力して削除する'"
                    :selected-label="'選択された'"
                    :loading="loadingContent"
                  ></multiselect>
                </div>
              </div>
            </CCol>
            <CCol sm="12">
              <label class="cp-label"
                >ポイント付与 現在ポイント：{{
                  Number(infoUser.numberPoint).toLocaleString("ja")
                }}pt</label
              >
              <div class="cp-4 m-0" style="display: flex">
                <CInput
                  placeholder="付与ポイントを入力"
                  v-model="pointAdd"
                  onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                />
                <CButton
                  v-on:click.prevent="addPoint()"
                  class="ml-3 btn-success"
                  style="min-width: 100px; margin-bottom: 1rem"
                >
                  ポイント付与
                </CButton>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12">
              <div class="d-flex justify-content-center">
                <CButton
                  v-on:click.prevent="createUser()"
                  :color="'success'"
                  :size="'lg'"
                  class="mt-3 mr-3 btn-color"
                  :disabled="loading"
                >
                  <b-spinner v-if="loading" small></b-spinner>
                  <span v-if="isEdit">変更する</span>
                  <span v-else>新規会員登録</span>
                </CButton>
                <CButton
                  v-on:click.prevent="goToListUser()"
                  :color="'secondary'"
                  :size="'lg'"
                  class="mt-3 back-btn"
                >
                  戻る
                </CButton>
              </div>
            </CCol>
          </CRow>
        </div>
        <b-modal id="active-modal-page" hide-header hide-footer>
          <div class="d-block text-center">
            <h5>
              すでに同システム利用の他サイトにて、登録済みです。
              ログイン情報を共通化させますか？
              （登録不要で、すぐログインが可能です。
            </h5>
          </div>
          <div class="d-flex justify-content-center">
            <b-button
              class="mx-2 mt-3 btn btn-danger"
              block
              @click="confirmAccount()"
              >受け入れる</b-button
            >
            <b-button
              class="mx-2 mt-3 btn btn-secondary"
              block
              @click="closeModal()"
            >
              キャンセル
            </b-button>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { FeedBack } from "@/utils/feedback.js";
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import $ from "jquery";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/regular.css";
import "@fortawesome/fontawesome-free/css/solid.css";
$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: "far fa-clock",
    date: "far fa-calendar",
    up: "fas fa-arrow-up",
    down: "fas fa-arrow-down",
    previous: "fas fa-chevron-left",
    next: "fas fa-chevron-right",
    today: "fas fa-calendar-check",
    clear: "far fa-trash-alt",
    close: "far fa-times-circle",
  },
});
export default {
  name: "CreateUser",
  data() {
    return {
      feedback: FeedBack,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      errorField: {
        lastNameRequired: null,
        firstNameRequired: null,
        emailRequired: null,
        emailInvalid: null,
        emailMaxlength: null,
        yearInvalid: null,
        monthInvalid: null,
        dateInvalid: null,
        zipCodeInvalid: null,
      },
      messageError: {
        lastNameRequired: "",
        firstNameRequired: "",
        emailRequired: "",
        emailInvalid: "",
        emailMaxlength: "",
        yearInvalid: "",
        monthInvalid: "",
        dateInvalid: "",
        zipCodeInvalid: "",
      },
      infoUser: {
        lastName: "",
        firstName: "",
        email: "",
        yearBirthDay: "",
        monthBirthDay: "",
        dateBirthDay: "",
        zipCodeOne: "",
        zipCodeTwo: "",
        prefecture: "",
        city: "",
        address: "",
        building: "",
        phone1: "",
        phone2: "",
        phone3: "",
        phoneInvalid1: null,
        messPhoneInvalid1: "",
        phoneInvalid2: null,
        messPhoneInvalid2: "",
        phoneInvalid3: null,
        messPhoneInvalid3: "",
        status: 1,
        timeCreate: "",
        timeLogin: "",
        password: "",
        passwordInvalid: false,
        passwordMaxlength: false,
        rank: "",
        numberPoint: 0,
        slags: [],
        isUserDeleted: false,
        contents: [],
      },
      pointAdd: "",
      listContentOptions: [],
      listSlagOptions: [],
      month31: ["01", "03", "05", "07", "08", "10", "12"],
      isEdit: false,
      loading: false,
      pointUser: 0,
      optionsStatus: Constants.STATUS_USER,
      optionsRank: ["rank 1", "rank 2"],
      optionsDate: {
        format: "YYYY-MM-DD HH:mm",
        useCurrent: false,
        showClear: false,
        showClose: false,
        locale: "ja",
      },
      NO: "",
      dataReuquest: null,
      loadingContent: false,
    };
  },
  created() {
    this.NO = this.$route.query.no;
    const { params } = this.$route;
    const { id } = params;
    const shop_id = this.shop_id;
    this.getShopByIdForAdmin(shop_id);
    const request = {
      shop_id: shop_id,
      limit: "all",
    };
    this.getALLlistSlag(request);
    const formData = {
      page: this.page,
      data: {
        shop_id: shop_id,
        limit: "all",
      },
    };
    this.getListContent(formData);
    if (id) {
      this.isEdit = true;
      const formData = {
        id: id,
        shop_id: shop_id,
      };
      this.getUserById(formData);
    }
  },
  computed: {
    ...mapGetters([
      "success",
      "message",
      "error",
      "listSlag",
      "userDetail",
      "listContent",
    ]),
  },
  watch: {
    userDetail() {
      if (Object.keys(this.userDetail).length) {
        const {
          first_name,
          last_name,
          email,
          birthday,
          postal_code,
          address,
          building_room,
          tel,
          created_at,
          login_at,
          point,
          is_active,
        } = this.userDetail;
        this.infoUser.firstName = first_name;
        this.infoUser.lastName = last_name;
        this.infoUser.email = email;
        this.infoUser.yearBirthDay = birthday ? birthday.split("-")[0] : "";
        this.infoUser.monthBirthDay = birthday ? birthday.split("-")[1] : "";
        this.infoUser.dateBirthDay = birthday ? birthday.split("-")[2] : "";
        this.infoUser.status = is_active;
        this.infoUser.zipCodeOne = postal_code
          ? postal_code.substring(0, 3)
          : "";
        this.infoUser.zipCodeTwo = postal_code
          ? postal_code.substring(3, 7)
          : "";
        this.getAddress(this.infoUser.zipCodeOne + this.infoUser.zipCodeTwo);
        this.infoUser.address = address;
        this.infoUser.building = building_room;
        this.infoUser.phone1 = tel ? tel.split("-")[0] : "";
        this.infoUser.phone2 = tel ? tel.split("-")[1] : "";
        this.infoUser.phone3 = tel ? tel.split("-")[2] : "";
        this.infoUser.timeCreate = new Date(created_at).toLocaleString("ja", {
          timeZone: "Asia/Tokyo",
        });
        this.infoUser.timeLogin = new Date(login_at).toLocaleString("ja", {
          timeZone: "Asia/Tokyo",
        });
        this.infoUser.numberPoint = parseInt(point);
        this.pointUser = parseInt(point);
        if (this.listSlagOptions.length) {
          this.createListSlag();
        }
        if (this.listContentOptions.length) {
          this.createListContent();
        }
        if (is_active === 0) {
          this.infoUser.isUserDeleted = true;
        }
      }
      console.log(this.userDetail);
    },
    listSlag() {
      this.listSlagOptions = this.listSlag.map((item) => ({
        slag_id: item.id,
        slag_name: item.slag_name ? item.slag_name : item.slag_name_en,
        slag_name_en: item.slag_name_en,
        slag_url: item.url,
        slag_description: item.description,
      }));
      if (Object.keys(this.userDetail).length) {
        if (this.userDetail.slag.length) {
          this.createListSlag();
        }
      }
    },
    listContent() {
      this.listContentOptions = this.listContent.map((item) => ({
        content_id: item.id,
        content_title: item.title,
      }));
      if (Object.keys(this.userDetail).length) {
        if (this.userDetail.content.length) {
          this.createListContent();
        }
      }
    },
    success() {
      if (this.success) {
        this.loading = false;

        this.$toasted.success(this.message);
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
        this.$router.push({
          name: this.$route.params.shopId ? "list user" : "list user domain",
        });
      }
    },
    error() {
      if (this.error) {
        this.loading = false;

        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    "infoUser.lastName"() {
      this.isLastNameValid();
    },
    "infoUser.firstName"() {
      this.isFirstNameValid();
    },
    "infoUser.email"() {
      if (this.infoUser.email) {
        this.errorField.emailRequired = null;
        this.messageError.emailRequired = "";
      }
      if (
        this.infoUser.email &&
        this.infoUser.email.match(Constants.REGEX_EMAIL)
      ) {
        this.errorField.emailInvalid = null;
        this.messageError.emailInvalid = "";
      }
      if (this.infoUser.email.length <= 255) {
        this.errorField.emailMaxlength = null;
        this.messageError.emailMaxlength = "";
      }
    },
    "infoUser.yearBirthDay"() {
      if (this.isYearValid) {
        this.errorField.yearInvalid = null;
        this.messageError.yearInvalid = "";
      }
      if (this.isMonthValid) {
        this.errorField.monthInvalid = null;
        this.messageError.monthInvalid = "";
      }
      if (this.isDateValid) {
        this.errorField.dateInvalid = null;
        this.messageError.dateInvalid = "";
      }
    },
    "infoUser.monthBirthDay"() {
      if (this.isMonthValid) {
        this.errorField.monthInvalid = null;
        this.messageError.monthInvalid = "";
      }
      if (this.isDateValid) {
        this.errorField.dateInvalid = null;
        this.messageError.dateInvalid = "";
      }
    },
    "infoUser.dateBirthDay"() {
      if (this.isDateValid) {
        this.errorField.dateInvalid = null;
        this.messageError.dateInvalid = "";
      }
    },
    "infoUser.zipCodeOne"() {
      this.errorField.zipCodeInvalid = null;
      this.messageError.zipCodeInvalid = "";
    },
    "infoUser.zipCodeTwo"() {
      this.errorField.zipCodeInvalid = null;
      this.messageError.zipCodeInvalid = "";
    },
    "infoUser.password"() {
      this.infoUser.passwordInvalid = false;
      this.infoUser.passwordMaxlength = false;
    },
    "infoUser.phone1"() {
      this.infoUser.phoneInvalid1 = null;
      this.infoUser.messPhoneInvalid1 = "";
    },
    "infoUser.phone2"() {
      this.infoUser.phoneInvalid2 = null;
      this.infoUser.messPhoneInvalid2 = "";
    },
    "infoUser.phone3"() {
      this.infoUser.phoneInvalid3 = null;
      this.infoUser.messPhoneInvalid3 = "";
    },
  },
  methods: {
    ...mapActions({
      updateUserOwner: "updateUserOwner",
      getUserById: "getUserById",
      getALLlistSlag: "getALLlistSlag",
      getShopByIdForAdmin: "getShopByIdForAdmin",
      CheckActiveMember: "CheckActiveMember",
      createUserOwner: "createUserOwner",
      getListContent: "getListContent",
    }),
    formatMonthAndDate() {
      if (this.infoUser.monthBirthDay.length === 1) {
        this.infoUser.monthBirthDay = "0" + this.infoUser.monthBirthDay;
      }
      if (this.infoUser.dateBirthDay.length === 1) {
        this.infoUser.dateBirthDay = "0" + this.infoUser.dateBirthDay;
      }
    },
    isFirstNameValid() {
      if (!this.isEdit) {
        if (!this.infoUser.firstName || this.infoUser.firstName.trim() === "") {
          this.errorField.firstNameRequired = false;
          this.messageError.firstNameRequired = FeedBack.FIRSTNAMENULL;
          return false;
        } else {
          this.errorField.firstNameRequired = null;
          this.messageError.firstNameRequired = "";
          return true;
        }
      }
    },
    isLastNameValid() {
      if (!this.isEdit) {
        if (!this.infoUser.lastName || this.infoUser.lastName.trim() === "") {
          this.errorField.lastNameRequired = false;
          this.messageError.lastNameRequired = FeedBack.LASTNAMENULL;
          return false;
        } else {
          this.errorField.lastNameRequired = null;
          this.messageError.lastNameRequired = "";
          return true;
        }
      }
    },
    isLeapYear(year) {
      return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
    },
    isDateValid() {
      const inputDay =
        this.infoUser.yearBirthDay +
        "-" +
        this.infoUser.monthBirthDay +
        "-" +
        this.infoUser.dateBirthDay;
      if (
        this.infoUser.dateBirthDay ||
        this.infoUser.monthBirthDay ||
        this.infoUser.yearBirthDay
      ) {
        const dateInFeb = this.isLeapYear(this.infoUser.yearBirthDay) ? 29 : 28;
        const dateInMonth = this.month31.includes(this.infoUser.monthBirthDay)
          ? 31
          : this.infoUser.monthBirthDay === "2"
          ? dateInFeb
          : 30;
        if (
          parseInt(this.infoUser.dateBirthDay) <= dateInMonth &&
          new Date(inputDay) < new Date()
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    isYearValid() {
      if (
        this.infoUser.dateBirthDay ||
        this.infoUser.monthBirthDay ||
        this.infoUser.yearBirthDay
      ) {
        if (parseInt(this.infoUser.yearBirthDay) <= new Date().getFullYear()) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    isMonthValid() {
      if (
        this.infoUser.dateBirthDay ||
        this.infoUser.monthBirthDay ||
        this.infoUser.yearBirthDay
      ) {
        if (parseInt(this.infoUser.monthBirthDay) <= 12) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    isZipCodeValid() {
      if (
        (this.infoUser.zipCodeTwo || this.infoUser.zipCodeOne) &&
        (!this.infoUser.prefecture ||
          !this.infoUser.city ||
          !this.infoUser.area)
      ) {
        return false;
      } else {
        return true;
      }
    },
    addPoint() {
      if (
        this.pointAdd &&
        this.infoUser.numberPoint + parseInt(this.pointAdd) <=
          Constants.LIMIT_POINT
      ) {
        this.infoUser.numberPoint += parseInt(this.pointAdd);
      } else {
        this.$toasted.error("制限を超えたポイント数を入力しています。");
      }
    },
    getAddress(postcode) {
      var postal_code = require("japan-postal-code");
      const _this = this;
      _this.infoUser.prefecture = "";
      _this.infoUser.city = "";
      _this.infoUser.area = "";
      postal_code.get(postcode, function (address) {
        _this.infoUser.prefecture = address.prefecture;
        _this.infoUser.area = address.area;
        _this.infoUser.city = address.city;
      });
    },
    async createUser() {
      this.loading = true;
      let error = false;
      if (!this.infoUser.email || this.infoUser.email.trim() === "") {
        this.errorField.emailRequired = false;
        this.messageError.emailRequired = FeedBack.EMAILNULL;
        error = true;
      }
      if (
        this.infoUser.email &&
        !this.infoUser.email.match(Constants.REGEX_EMAIL)
      ) {
        this.errorField.emailInvalid = false;
        this.messageError.emailInvalid = FeedBack.EMAIL_INVALID;
        error = true;
      }
      if (this.infoUser.email.length > 255) {
        this.errorField.emailMaxlength = false;
        this.messageError.emailMaxlength = FeedBack.MAX_LENGTH_255;
        error = true;
      }
      if (
        !this.$route.params.id &&
        (!this.infoUser.firstName || this.infoUser.firstName.trim() === "")
      ) {
        this.errorField.firstNameRequired = false;
        this.messageError.firstNameRequired = FeedBack.FIRSTNAMENULL;
        error = true;
      }
      if (
        !this.$route.params.id &&
        (!this.infoUser.lastName || this.infoUser.lastName.trim() === "")
      ) {
        this.errorField.lastNameRequired = false;
        this.messageError.lastNameRequired = FeedBack.LASTNAMENULL;
        error = true;
      }
      if (!this.isYearValid()) {
        this.errorField.yearInvalid = false;
        this.messageError.yearInvalid = FeedBack.INVALID;
        error = true;
      }
      if (!this.isMonthValid()) {
        this.errorField.monthInvalid = false;
        this.messageError.monthInvalid = FeedBack.INVALID;
        error = true;
      }
      if (!this.isDateValid()) {
        this.errorField.dateInvalid = false;
        this.messageError.dateInvalid = FeedBack.INVALID;
        error = true;
      }
      if (!this.isZipCodeValid()) {
        this.errorField.zipCodeInvalid = false;
        this.messageError.zipCodeInvalid = FeedBack.INVALID;
        error = true;
      }
      if (this.infoUser.password && this.infoUser.password.length < 8) {
        error = true;
        this.infoUser.passwordMaxlength = true;
      }
      if (
        this.infoUser.password &&
        !this.infoUser.password.match(Constants.CHECK_PASSWORD)
      ) {
        error = true;
        this.infoUser.passwordInvalid = true;
      }
      if (
        this.infoUser.phone1 &&
        !this.infoUser.phone1.match(Constants.REGEX_NUMBER)
      ) {
        this.infoUser.phoneInvalid1 = false;
        this.infoUser.messPhoneInvalid1 = FeedBack.INVALID;
        error = true;
      }
      if (
        this.infoUser.phone2 &&
        !this.infoUser.phone2.match(Constants.REGEX_NUMBER)
      ) {
        this.infoUser.phoneInvalid2 = false;
        this.infoUser.messPhoneInvalid2 = FeedBack.INVALID;
        error = true;
      }
      if (
        this.infoUser.phone3 &&
        !this.infoUser.phone3.match(Constants.REGEX_NUMBER)
      ) {
        this.infoUser.phoneInvalid3 = false;
        this.infoUser.messPhoneInvalid3 = FeedBack.INVALID;
        error = true;
      }
      if (!error) {
        let slag_id = [];
        let content_id = [];
        const {
          firstName,
          lastName,
          email,
          yearBirthDay,
          monthBirthDay,
          dateBirthDay,
          zipCodeOne,
          zipCodeTwo,
          prefecture,
          area,
          city,
          address,
          building,
          phone1,
          phone2,
          phone3,
          numberPoint,
          slags,
          contents,
          password,
          status,
        } = this.infoUser;
        if (slags.length) {
          slags.forEach((item) => {
            slag_id.push(item.slag_id);
          });
        }
        if (contents.length) {
          contents.forEach((item) => {
            content_id.push(item.content_id);
          });
        }
        const formCreateUser = {
          shop_id: this.shop_id,
          email,
          first_name: firstName,
          last_name: lastName,
          birthday:
            yearBirthDay && monthBirthDay && dateBirthDay
              ? yearBirthDay + "-" + monthBirthDay + "-" + dateBirthDay
              : "",
          postal_code: zipCodeOne && zipCodeTwo ? zipCodeOne + zipCodeTwo : "",
          province: prefecture,
          municipalities: area,
          city: city,
          address,
          building_room: building,
          tel: phone1 + "-" + phone2 + "-" + phone3,
          point: this.isEdit ? numberPoint - this.pointUser : numberPoint,
          slag_id: slag_id,
          content_id: content_id,
          is_active: status,
        };
        if (this.$route.params.id) {
          if (password) {
            formCreateUser.password = password;
          }
          formCreateUser.id = this.$route.params.id;
          await this.$store.dispatch("updateUserOwner", formCreateUser);
        } else {
          const isShowActive = await this.$store.dispatch(
            "CheckActiveMember",
            formCreateUser
          );
          if (isShowActive.success) {
            if (isShowActive.data == "active") {
              this.dataReuquest = formCreateUser;
              this.$bvModal.show("active-modal-page");
            } else {
              this.$toasted.success(isShowActive.message);
              if (isShowActive.message2 !== null) {
                this.$toasted.error(isShowActive.message2);
              }
              this.$router.go(-1);
            }
          }
        }
        this.loading = false;
      } else {
        this.loading = false;
        this.$toasted.error("必須項目のご入力をお願いします。");
      }
    },
    getListUsers() {
      const formData = {
        page: 0,
        data: {
          shop_id: this.shop_id,
          id: this.$route.params.id ? this.$route.params.id : "",
        },
      };
      this.getListUser(formData);
    },
    goToListUser() {
      this.$router.go(-1);
    },
    createListSlag() {
      if (this.infoUser.slags.length === 0) {
        this.listSlagOptions.forEach((element) => {
          this.userDetail.slag.forEach((item) => {
            if (item.id == element.slag_id) {
              this.infoUser.slags.push(element);
            }
          });
        });
      }
      // console.log(userDetail)
      // this.$store.commit("set", ["userDetail", {}]);
    },
    createListContent() {
      if (this.infoUser.contents.length === 0) {
        this.listContentOptions.forEach((element) => {
          this.userDetail.content.forEach((item) => {
            if (item.id == element.content_id) {
              this.infoUser.contents.push(element);
            }
          });
        });
      }
      this.$store.commit("set", ["userDetail", {}]);
    },
    goToListNote() {
      if (this.$route.params.shopId) {
        this.$router.push({
          name: "ListUserNote",
          params: {
            shopId: this.shop_id,
            id: this.$route.params.id,
          },
        });
      } else {
        this.$router.push({
          name: "ListUserNote domain",
          params: {
            id: this.$route.params.id,
          },
        });
      }
    },
    goToListPurchase() {
      if (this.$route.params.shopId) {
        this.$router.push({
          name: "purchase user",
          params: {
            shopId: this.shop_id,
            id: this.$route.params.id,
          },
        });
      } else {
        this.$router.push({
          name: "purchase user domain",
          params: {
            id: this.$route.params.id,
          },
        });
      }
    },
    async confirmAccount() {
      await this.createUserOwner(this.dataReuquest);
      this.$bvModal.hide("active-modal-page");
    },
    closeModal() {
      this.$bvModal.hide("active-modal-page");
    },
  },
};
</script>
<style lang="scss">
.create-user {
  div {
    div {
      div {
        label {
          background: #f2f2f2 !important;
          height: 40px;
          align-items: center;
          display: flex;
        }
      }
      label {
        background: #d5d5d5;
        height: 40px;
        align-items: center;
        display: flex;
      }
    }
  }
}
.required {
  color: #fff;
  background: red;
  padding: 2px 8px;
  border-radius: 5px;
  top: 0;
  margin-left: 15px;
  font-size: 11px;
}
.create-content-main
  .custom-content
  .custome-multi-slag
  .multiselect__tag-icon {
  display: none;
}
</style>
